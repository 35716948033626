<template>
  <v-dialog v-model="$props.value" max-width="1000" persistent :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card class="fill-height">
      <v-card-text class="pa-0 fill-height">
        <v-card-title class="headline font-weight-light pb-1"> Import List Tool </v-card-title>

        <v-stepper
          v-model="tab"
          class="elevation-0 transparent"
          :class="[{ 'fill-height': tab === 2 }]"
          :height="tab == 2 ? '600px' : null"
          non-linear
        >
          <v-stepper-header class="elevation-0 mx-6 mt-6">
            <v-stepper-step :complete="tab > 1" step="1">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">01</span>
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold">Subir Archivo</span>
                </div>
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="tab > 2" step="2">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">02</span>
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold">Coincidir Campos</span>
                </div>
              </div>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              <div class="d-flex align-center">
                <span class="text--primary text-4xl font-weight-bold me-3">03</span>
                <div class="d-flex flex-column">
                  <span class="text--primary text-sm font-weight-semibold">Resumen</span>
                </div>
              </div>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="fill-height">
            <div
              class="overflow-y-auto"
              :style="[
                {
                  'min-height': 'calc(100% - 96px) !important',
                },
                {
                  'max-height': 'calc(100% - 96px) !important',
                },
              ]"
              ref="stepperItems"
            >
              <v-stepper-content step="1">
                <!-- File drop box -->
                <div
                  class="pa-12 justify-center text-center border-accent-dashed"
                  :class="fileDropBoxClass"
                  style="cursor: pointer"
                  @click="launchFilePicker"
                  @mouseover="dragover"
                  @mouseleave="dragleave"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <v-icon size="120" :color="iconColor"> mdi-cloud-upload </v-icon>

                  <input
                    type="file"
                    ref="file"
                    @change="onChange($event.target.files)"
                    style="display: none"
                    accept="text/csv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet"
                  />

                  <div class="d-block">
                    <span v-show="!buttons.continue.loading">
                      Elija o arrastre un archivo XLSX, ODS, CSV, TXT
                      <br />
                      Tamaño máximo
                      {{ this.$props.fileSize / 1e6 }} MB
                    </span>

                    <!-- File data -->
                    <div v-if="file" class="text text-center mt-4">
                      <v-icon small>mdi-paperclip</v-icon>
                      {{ file.name }} - {{ Math.round((file.size / 1024) * 100) / 100 }} KB
                      <v-icon small @click.stop="removeFile" v-show="!buttons.continue.loading" class="ml-4"
                        >mdi-close</v-icon
                      >
                    </div>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2" class="pt-0">
                <v-row no-gutters>
                  <v-col v-if="errors.length">
                    <v-alert dense outlined type="error">
                      Por favor revise los siguientes errores:
                      <ul>
                        <li v-for="(error, index) in errors" :key="index">
                          {{ error.text }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-checkbox v-model="checkHeaders" label="Contiene encabezados" class="mt-0"></v-checkbox
                  ></v-col>

                  <v-col cols="12">
                    <v-row v-for="(column, indx) in fileColumns" :key="indx">
                      <v-col cols="6" class="mb-5">
                        <p class="font-weight-bold" v-if="checkHeaders">
                          {{ uploadedPreview.at(0).at(column) }}
                        </p>
                        <v-list dense class="rounded pa-0 border-list" style="background-color: transparent">
                          <template
                            v-for="(upload, index) in uploadedPreview.slice(checkHeaders ? 1 : 0, checkHeaders ? 4 : 3)"
                          >
                            <v-list-item dense :key="index">
                              <v-list-item-content>
                                <v-list-item-title v-text="upload.at(column)"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider
                              v-if="
                                index < uploadedPreview.slice(checkHeaders ? 1 : 0, checkHeaders ? 4 : 3).length - 1
                              "
                              :key="'divider' + index"
                              class="color-divider"
                            ></v-divider>
                          </template>
                        </v-list>
                      </v-col>
                      <v-col cols="6" class="mb-5">
                        <p class="font-weight-bold">Pertenece a:</p>
                        <v-select
                          @change="onSelect($event, column)"
                          v-model="selects[column]"
                          :items="
                            [
                              {
                                text: 'Saltar esta columna',
                                value: null,
                              },
                            ].concat($props.columns)
                          "
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                          clearable
                          hide-details="auto"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" v-for="col in saveColumns" :key="col.columnSystem">
                          {{ $props.columns.filter(c => c.value == col.columnSystem)[0].text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(upload, index) in uploadedPreview.slice(checkHeaders ? 1 : 0, checkHeaders ? 4 : 3)"
                        :key="index"
                      >
                        <td class="text-left" v-for="col in saveColumns" :key="col.columnFile">
                          {{ upload.at(col.columnFile) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-stepper-content>
            </div>
          </v-stepper-items>
        </v-stepper>

        <v-card-actions class="pa-4 pt-4">
          <v-spacer />
          <v-btn text outlined @click.native="tab == 1 ? closeDialog() : (tab += -1)" :disabled="buttons.close.disabled"
            >Atrás</v-btn
          >
          <v-btn
            color="primary"
            :loading="buttons.continue.loading"
            class="px-5"
            depressed
            :disabled="buttons.continue.disabled || !this.file"
            @click.native="tab == 1 ? uploadFile() : tab == 2 ? summary() : makeJSON()"
            >{{ tab == 3 ? 'Finalizar' : 'Continuar' }}</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Worker from 'worker-loader!./importListWorker'
import snackbarPlugin from '@/plugins/snackbar'

export default {
  name: 'ImportListTool',

  props: {
    value: { type: Boolean },
    type: { type: String },
    columns: { type: Array },
    fileSize: { type: Number, default: 82000000 },
  },

  watch: {
    value: {
      handler: function (val) {
        if (val) {
          this.importWorker = new Worker()
          this.importWorker.addEventListener(
            'message',
            e => {
              this.processUploaded(e.data)
              this.tab = 2
              this.buttons.continue.loading = false
            },
            false,
          )
        } else {
          this.importWorker.terminate()
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      dialogs: {},
      tab: 1,
      file: null,
      filename: '',
      checkHeaders: false,
      uploaded: [],
      uploadedPreview: [],
      fileColumns: [],
      inputs: [],
      selects: [],
      errors: [],
      disableButtons: false,
      importWorker: null,
      buttons: {
        continue: {
          loading: false,
          disabled: false,
        },
        close: {
          disabled: false,
        },
      },
      saveColumns: [],
      fileTypesAccepted: {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true,
        'text/csv': true,
        'text/plain': true,
        'application/vnd.oasis.opendocument.spreadsheet': true,
      },
      fileDropBoxClass: '',
      iconColor: 'grey darken-2',
    }
  },

  async created() {},

  methods: {
    dragover(event) {
      event.preventDefault()
      this.fileDropBoxClass = this.$vuetify.theme.dark ? 'bg-414141' : 'bg-f0f0f0'
      this.iconColor = 'grey darken-1'
    },

    dragleave(event) {
      event.preventDefault()
      this.fileDropBoxClass = ''
      this.iconColor = 'grey darken-2'
    },

    drop(event) {
      event.preventDefault()
      this.onChange(event.dataTransfer.files) // Trigger the onChange event manually
    },

    removeFile() {
      this.$refs.file.value = null
      this.cleanFile()
      this.cleanAll()
    },

    launchFilePicker() {
      this.removeFile()
      this.$refs.file.click()
    },

    onChange(file) {
      try {
        if (file && file[0] && this.fileTypesAccepted[file[0].type]) {
          if (file[0].size <= this.$props.fileSize) {
            this.cleanFile()
            this.cleanAll()
            this.file = file[0]
            this.filename = file[0].name
          } else {
            snackbarPlugin.showMessage({
              content: `Tamaño máximo permitido ${this.$props.fileSize / 1e6} MB`,
              color: 'error',
              timeout: 10000,
            })
          }
        } else {
          snackbarPlugin.showMessage({ content: 'Archivo inválido', color: 'error', timeout: 10000 })
        }
      } catch (e) {
        snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
      }
    },

    async uploadFile() {
      if (this.file) {
        this.buttons.continue.loading = true
        this.fileDropBoxClass = ''
        this.cleanAll()
        this.importWorker.postMessage(this.file)
      }
    },

    processUploaded(uploaded) {
      try {
        if (uploaded.length > 0) {
          this.uploaded = uploaded

          //Preview rows
          for (const [index, row] of uploaded.entries()) {
            this.uploadedPreview.push(row)
            if (index == 4) break
          }

          //Set columns
          this.fileColumns = Array.from({ length: uploaded.at(0).length }, (n, i) => i)

          //Default selected option
          this.selects = Array.from({ length: uploaded.at(0).length }, () => null)

          this.uploaded = uploaded

          //Restore savedColumns from LocalStorage
          let savedColumnsStorage = ''

          savedColumnsStorage = this.getFromLocalStorage('fileImport')

          if (savedColumnsStorage) {
            let saveColumns = JSON.parse(savedColumnsStorage)
            for (var i = 0; i < uploaded.at(0).length; i++) {
              if (saveColumns[i] && this.$props.columns.some(col => col.value == saveColumns[i].columnSystem)) {
                if (typeof this.selects[saveColumns[i].columnFile] !== 'undefined') {
                  this.selects[saveColumns[i].columnFile] = saveColumns[i].columnSystem
                  this.saveColumns.push(saveColumns[i])
                }
              }
            }
            if (typeof saveColumns[saveColumns.length - 1].checkHeaders == 'boolean') {
              this.checkHeaders = saveColumns[saveColumns.length - 1].checkHeaders
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    },

    summary() {
      this.errors = []
      if (this.saveColumns.length) {
        //Check required fields
        this.$props.columns.forEach(col => {
          if (col.required) {
            if (!this.saveColumns.some(saved => saved.columnSystem == col.value))
              this.errors.push({
                text: `${col.text} es requerido`,
              })
          }
        })
      } else {
        this.errors.push({
          text: 'Debe seleccionar al menos una columna',
        })
      }

      if (!this.errors.length) this.tab = 3
      else this.$refs.stepperItems.scrollTop = 0
    },

    onSelect(val, column) {
      //If already exist selected column
      let existColSys = this.saveColumns.findIndex(col => col.columnSystem == val)

      if (existColSys !== -1) {
        this.selects[this.saveColumns.at(existColSys).columnFile] = null
        this.saveColumns.splice(existColSys, 1)
      }

      let existColFile = this.saveColumns.findIndex(col => col.columnFile == column)

      if (existColFile !== -1) this.saveColumns.splice(existColFile, 1)

      if (val) {
        this.saveColumns.push({
          columnSystem: val == 'new-field' ? this.inputs.at(column) : val,
          columnFile: column,
        })
      } else {
        this.selects[column] = null
      }
    },

    makeJSON() {
      try {
        let json = []
        this.buttons.continue.loading = true

        this.saveInLocalStorage(
          'fileImport',
          JSON.stringify(this.saveColumns.concat([{ checkHeaders: this.checkHeaders }])),
        )

        for (const [index, row] of this.uploaded.entries()) {
          //If there are headers skip this row
          if (index == 0 && this.checkHeaders) continue

          let obj = {}

          //Fields
          this.saveColumns.forEach(paran => {
            let colSysIndex = this.$props.columns.findIndex(c => c.value == paran.columnSystem)
            if (typeof this.$props.columns.at(colSysIndex).replace !== 'undefined' && row[paran.columnFile]) {
              obj[paran.columnSystem] = this.$props.columns.at(colSysIndex).replace(row[paran.columnFile])
            }
            if (typeof this.$props.columns.at(colSysIndex).validation !== 'undefined') {
              let validation = this.$props.columns
                .at(colSysIndex)
                .validation(obj[paran.columnSystem] ? obj[paran.columnSystem] : row[paran.columnFile])
              if (validation == true)
                obj[paran.columnSystem] = obj[paran.columnSystem] ? obj[paran.columnSystem] : row[paran.columnFile]
              else {
                if (typeof validation == 'string')
                  this.errors.push({
                    text: `${validation} en la linea ${index + 1}`,
                  })
                else this.addError(paran.columnSystem, index)
              }
            }
          })

          json.push(obj)

          if (this.errors.length > 0) break
        }

        if (json.length && this.errors.length == 0) {
          this.$emit('result', json)

          this.closeDialog()

          snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
        } else {
          this.tab = 2
          this.buttons.continue.loading = false
        }
      } catch (error) {
        console.log(error)
        this.errors.push({
          text: `Error inesperado. Contacte a un administrador`,
        })
        this.tab = 2
        this.buttons.continue.loading = false
      }
    },

    addError(column, row) {
      let searchColName = this.$props.columns.filter(col => col.value == column)
      let colName = ''
      if (searchColName.length > 0) colName = searchColName.at(0).text
      else colName = column
      this.errors.push({
        text: `Error en la columna ${colName} en la linea ${row + 1}`,
      })
    },

    closeDialog() {
      this.cleanFile()
      this.cleanAll()
      this.$emit('input', false)
      this.tab = 1
    },

    cleanFile() {
      this.filename = ''
      this.file = null
    },

    cleanAll() {
      this.uploaded = []
      this.uploadedPreview = []
      this.errors = []
      this.fileColumns = []
      this.saveColumns = []
      this.inputs = []
      this.selects = []
      this.checkHeaders = false
      this.buttons = {
        continue: {
          loading: false,
          disabled: false,
        },
        close: {
          disabled: false,
        },
      }
    },

    saveInLocalStorage(key, value) {
      localStorage.setItem(key, value)
    },

    getFromLocalStorage(key) {
      if (localStorage.getItem(key) != null) {
        return localStorage.getItem(key)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.border-accent-dashed {
  border: 2px var(--v-secondary-base) dashed;
}
.border-list {
  border: 1px var(--v-secondary-base) solid;
}
.color-divider {
  border-color: var(--v-secondary-base);
}
.bg-414141 {
  background-color: #414141;
}
.bg-f0f0f0 {
  background-color: #f0f0f0;
}
</style>
